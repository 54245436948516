import './SettingsModal.css';
import { Close } from '@mui/icons-material';
import { Button, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { Level } from '../../enums/level.enum';
import { LocalStorageKey } from '../../enums/local-storage-key.enum';

function SettingsModal(props) {
  const statistics = localStorage.getItem(LocalStorageKey.STATISTICS);
  const [selectedLevel, setSelectedLevel] = useState(localStorage.getItem(LocalStorageKey.SELECTED_LEVEL));
  const [isColorBlindFriendly, setIsColorBlindFriendly] = useState(localStorage.getItem(LocalStorageKey.IS_COLOR_BLIND_FRIENDLY) === 'true');

  let numGames = 0;
  let numWon = 0;
  let winPercentage = 0;

  if (statistics) {
    const statisticsJSON = JSON.parse(statistics);
    numGames = statisticsJSON[selectedLevel].numGames;
    numWon = statisticsJSON[selectedLevel].numWon;

    if (numGames !== 0) {
      winPercentage = Math.round(100 * numWon/numGames);
    }
  }

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    localStorage.setItem(LocalStorageKey.SELECTED_LEVEL, event.target.value);
    props.handleLevelChange();
  };

  const handleColorBlindFriendlyChange = (event) => {
    setIsColorBlindFriendly(event.target.checked);
    localStorage.setItem(LocalStorageKey.IS_COLOR_BLIND_FRIENDLY, event.target.checked);
    props.handleColorBlindFriendlyChange(event.target.checked);
  }

  return (
    <div className='settings-modal'>
      <div className='settings-modal-header'>
        <Button className='close-btn' onClick={props.handleClose}><Close className='close-icon'/></Button>
      </div>
      <div className='settings-modal-content'>
        <div className='level-selection'>
          <div className='settings-modal-content-title'>Level</div>
          <ToggleButtonGroup
            value={selectedLevel}
            onChange={handleLevelChange}
            exclusive
            color='primary'
          >
            <ToggleButton className='level-btn' value={Level.EASY}>Easy</ToggleButton>
            <ToggleButton className='level-btn' value={Level.NORMAL}>Normal</ToggleButton>
            <ToggleButton className='level-btn' value={Level.HARD}>Hard</ToggleButton>
          </ToggleButtonGroup>
          <div className='level-hint'>{getHintByLevel(selectedLevel as Level)}</div>
        </div>
        <table className='statistics'>
          <caption className='settings-modal-content-title'>Statistics</caption>
          <tbody>
            <tr className='statistics-row'>
              <td className='statistics-row-title'>Games Played</td>
              <td className='statistics-row-data'>{ numGames }</td>
            </tr>
            <tr className='statistics-row'>
              <td  className='statistics-row-title'>Games Won</td>
              <td className='statistics-row-data'>{ numWon }</td>
            </tr>
            <tr className='statistics-row'>
              <td className='statistics-row-title'>Win percentage</td>
              <td className='statistics-row-data'>{ winPercentage }%</td>
            </tr>
          </tbody>
        </table>
        <div className='color-blind-friendly'>
          <div className='settings-modal-content-title color-blind-friendly-label'>Color-Blind Friendly</div>
          <Switch
            checked={isColorBlindFriendly}
            onChange={handleColorBlindFriendlyChange}
          />
        </div>
      </div>
    </div>
  )
}

/**
 * Helper Methods
 */

const getHintByLevel = (level: Level) => {
  switch(level) {
    case Level.EASY:
      return '3 digits, No Repeating Numbers';
    case Level.NORMAL:
      return '4 digits, No Repeating Numbers';
    case Level.HARD:
      return '4 digits, May Contain Repeating Numbers';
}
}

export default SettingsModal;