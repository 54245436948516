import './ResetGameModal.css';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ModalAction } from '../../enums/modal-action.enum';

function ResetGameModal(props) {
  return (
    <div className='reset-game-modal'>
      <div className='reset-game-modal-header'>
        <Button className='close-btn' onClick={() => props.handleClose(ModalAction.CANCEL)}><Close className='close-icon'/></Button>
      </div>
      <div className='reset-game-modal-content'>
        <p>Are you sure you'd like to reset the game?</p>
        <Button className='reset-confirmation-btn' onClick={() => props.handleClose(ModalAction.ACTION)}>Reset</Button>
      </div>
    </div>
  )
}

export default ResetGameModal;