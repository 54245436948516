import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import Game from './components/Game/Game';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#845EC2',
      },
      secondary: {
        main: '#9CC25E',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Game />
      </div>
    </ThemeProvider>
  );
}

export default App;
