export const generateNonRepeatingNumber = (numDigits: number) => {
  const guess = [];
  for (let i = 0; i < numDigits; i++) {
    guess.push('');
  }
  
  let index = 0;

  while (index < numDigits) {
    const digit = generateRandomDigit();

    if (!guess.includes(digit)) {
      guess[index] = digit;
      index++;
    }
  }

  return guess;
}

export const generateNumberAllowsRepeating = (numDigits: number) => {
  const guess = [];
  for (let i = 0; i < numDigits; i++) {
    guess.push('');
  }

  for (let i = 0; i < numDigits; i++) {
    guess[i] = generateRandomDigit();
  }

  return guess;
}

export const generateRandomDigit = () => {
  return Math.floor(Math.random() * 10).toString();
}