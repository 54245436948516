import './NumberPad.css';
import { Backspace } from '@mui/icons-material';

function NumberPad(props) {
  const handleNumberSelection = (event) => {
    const className = event.target.className;

    if (typeof className !== 'string') {
      return;
    }

    if (className.includes('backspace')) {
      props.handleNumberDeletion();
    } else if (className.includes('digit-')) {
      const digit = className.substring(className.lastIndexOf('-') + 1);
      props.handleNumberSelection(digit);
    }
  };

  const generateHalfViewNumberPad = () => {
    const rows = [];
    for (let i = 0; i < 3; i++) {
      const digits = [];
      for (let j = 0; j < 3; j++) {
        const num = (i * 3) + j + 1;
        digits.push((
          <div className={`number-half-view digit-${num}`} key={`num-pad-digit-${num}`} onClick={handleNumberSelection}>{num}</div>
        ));
      }
      rows.push((
        <div className='number-pad-row-half-view' key={`num-pad-row-${i}`}>
          {digits}
        </div>
      ));
    }
    rows.push((
      <div className='number-pad-row-half-view' key='num-pad-row-3'>
        <div className='number-half-view backspace' onClick={props.handleNumberDeletion}><Backspace className='backspace-icon-half-view'/></div>
        <div className='number-half-view digit-0' key='num-pad-digit-0' onClick={handleNumberSelection}>0</div>
        <div className='number-half-view enter-half-view' onClick={props.handleNumberSubmit}>Enter</div>
      </div>
    ));
    return (
      <div className='number-pad-half-view'>
        {rows}
      </div>
    );
  };

  const generateFullViewNumberPad = () => {
    return (
      <div className='number-pad-full-view'>
        <div className='number-pad-row-full-view' key={`num-pad-full-view-row-0`}>
          <div className={`number-full-view digit-1`} key={`num-pad-digit-1`} onClick={handleNumberSelection}>1</div>
          <div className={`number-full-view digit-2`} key={`num-pad-digit-2`} onClick={handleNumberSelection}>2</div>
          <div className={`number-full-view digit-3`} key={`num-pad-digit-3`} onClick={handleNumberSelection}>3</div>
          <div className={`number-full-view digit-4`} key={`num-pad-digit-4`} onClick={handleNumberSelection}>4</div>
          <div className={`number-full-view digit-5`} key={`num-pad-digit-5`} onClick={handleNumberSelection}>5</div>
          <div className={`number-full-view digit-6`} key={`num-pad-digit-6`} onClick={handleNumberSelection}>6</div>
        </div>
        <div className='number-pad-row-full-view' key={`num-pad-full-view-row-1`}>
          <div className={`number-full-view digit-7`} key={`num-pad-digit-7`} onClick={handleNumberSelection}>7</div>
          <div className={`number-full-view digit-8`} key={`num-pad-digit-8`} onClick={handleNumberSelection}>8</div>
          <div className={`number-full-view digit-9`} key={`num-pad-digit-9`} onClick={handleNumberSelection}>9</div>
          <div className={`number-full-view digit-0`} key={`num-pad-digit-0`} onClick={handleNumberSelection}>0</div>
          <div className='number-full-view backspace' onClick={props.handleNumberDeletion}><Backspace className='backspace-icon-full-view'/></div>
          <div className='number-full-view enter-full-view' onClick={props.handleNumberSubmit}>Enter</div>
        </div>
      </div>
    );
  }

  return props.isNotePadOpen ? generateHalfViewNumberPad() : generateFullViewNumberPad();
}

export default NumberPad;