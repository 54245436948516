import './GameOverModal.css';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import confetti from 'canvas-confetti';
import { useMemo } from 'react';

function GameOverModal(props) {
  const modal = useMemo(() => {
    const headerText = props.isWinner ? 'O-Numbo!' : 'Try Again';

    if (props.isOpen) {
      // dialog isn't loaded right away, so need to set timeout
      setTimeout(() => {
        const canvas = document.getElementById('canvas') as HTMLCanvasElement;
        const confettiFn = confetti.create(canvas);
      
        if (props.isWinner) {
          confettiFn({
            particleCount: 150,
            startVelocity: 30,
          });
        }
      }, 100);
  
      return (
        <div className='game-over-modal'>
          <div className='game-over-modal-header'>
            <Button className='close-btn' onClick={props.handleClose}><Close className='close-icon'/></Button>
          </div>
          <div id='content' className='content'>
            <h1>{ headerText }</h1>
            <h1>{props.solution.toString().replaceAll(',', '')}</h1>
          </div>
          <canvas id='canvas'></canvas>
          <Button onClick={props.handleGameReset} className='reset-btn'>Play Again</Button>
        </div>
      )
    }
  }, [props]);

  return (
   modal
  )
}

export default GameOverModal;