import './HelpModal.css';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SettingsIcon from '@mui/icons-material/Settings';

function HelpModal(props) {
  return (
    <div className='help-modal'>
      <div className='help-modal-header'>
        <Button className='close-btn' onClick={props.handleClose}><Close className='close-icon'/></Button>
      </div>
      <div className='help-modal-content'>
        <h3>How to Play</h3>
        <ul>
          <li>The goal of the game is to guess the mystery number without running out of guesses</li>
          <li>Enter different combinations of either 3 or 4 digit numbers (dependeing on the level)</li>
          <li>After each guess, each circle to the left will tell you information about the number you entered</li>
          <li><span className='help-circle help-blue-circle'>{props.isColorBlindFriendly ? 'B' : ''}</span>= 1 number is in the correct spot</li>
          <li><span className='help-circle help-pink-circle'>{props.isColorBlindFriendly ? 'P' : ''}</span>= 1 number is in the solution but not the correct spot</li>
          <li><SentimentDissatisfiedIcon className='help-no-results'/>= 0 numbers are in the solution</li>
          <li>If you guess the mystery number, O-Numbo! You win!</li>
        </ul>
        <h3>Levels</h3>
        <ul>
          <li><b>Easy</b> - 3 digits, No Repeating Numbers</li>
          <li><b>Normal</b> - 4 digits, No Repeating Numbers</li>
          <li><b>Hard</b> - 4 digits, May Contain Repeating Numbers</li>
        </ul>
        <h3>Color-Blind Friendly Mode</h3>
        <p>To turn on the color-blind friendly mode, go to Settings <SettingsIcon className='help-settings-icon'/> and toggle the Color-Blind Friendly option.</p>
      </div>
    </div>
  )
}

export default HelpModal;